import React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import {FaFacebookF, FaInstagram, FaLinkedin, FaTwitter} from "react-icons/fa"
import Newsletter from "./newsletter"

const Footer = () => {
    return (
      <div className="pt-24 bg-white border-t-2">
        <div className="container mx-auto px-10 md:px-16 text-gray-100 grid grid-cols-1 md:grid-cols-3 auto-cols-max justify-around pb-10 ">
          {/* Entreprenuership Logo */}
          <div className="mt-5">
            <Link to="/">
              <StaticImage
                src="../images/logos/digital-entrepreneurship-logo.png"
                alt="Woman"
                width="500"
              />
            </Link>
            <p className="mt-3 pr-10 leading-loose">
              Sierra Leone is on the path of building a robust entrepreneurial
              ecosystem with the primary aim of supporting budding
              entrepreneurs, while striving to build a central system that
              fosters efficient cross-sectoral coordination and effective
              collaboration in the space. Below are partners in the journey of
              making Sierra Leone an innovation and entrepreneurship hub.
            </p>
            <div className="mt-10">
              <div className="grid grid-cols-3">
                <Link to="/partners/#dsti">
                  <StaticImage
                    src="../images/footer/dsti.jpeg"
                    alt="DSTI"
                    width="100"
                    className="mr-2"
                  />
                </Link>
                <Link to="/partners/#smeda">
                  <StaticImage
                    src="../images/footer/smeda.jpeg"
                    alt="SMEDA"
                    width="200"
                    className="mr-2 mt-5"
                  />
                </Link>
                <Link to="/partners/#sledp">
                  <StaticImage
                    src="../images/footer/sledp.jpeg"
                    alt="SLEDP"
                    width="90"
                    className="ml-4"
                  />
                </Link>
              </div>

              <div className="mt-5">
                <div className="grid grid-cols-3">
                  <Link to="/partners/#undp">
                    <StaticImage
                      src="../images/footer/undp.jpeg"
                      alt="UNDP"
                      width="80"
                      className="mr-4"
                    />
                  </Link>
                  <Link to="/partners/#uncdf">
                    <StaticImage
                      src="../images/footer/uncdf.jpeg"
                      alt="UNCDF"
                      width="100"
                      className="mr-4 mt-5"
                    />
                  </Link>
                  <Link to="/partners/#invest-salone">
                    <StaticImage
                      src="../images/footer/invest-salone.jpeg"
                      alt="Invest-Salone"
                      width="200"
                      className="mt-5"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Completed mobile responsiveness */}

          {/* Quick site links */}
          <div className="mt-16 md:mt-5 md:ml-28 ml-0">
            <div>
              <h1 className="text-2xl font-semibold tracking-wide text-gray-600">
                Site Links
              </h1>
              <ul className="mt-5">
                <li>
                  <Link
                    to="/"
                    activeClassName="active"
                    className="text-gray-600 hover:text-blue-500"
                  >
                    Home
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to="/about"
                    activeClassName="active"
                    className="text-gray-600 hover:text-blue-500"
                  >
                    About
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to="/team"
                    activeClassName="active"
                    className="text-gray-600 hover:text-blue-500"
                  >
                    Team
                  </Link>
                </li>
                <li className="mt-2">
                  <a
                    href="https://finder.entrepreneur.gov.sl/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-600 hover:text-blue-500"
                  >
                    Ecosystem
                  </a>
                </li>
                <li className="mt-2">
                  <Link
                    to="/blog"
                    activeClassName="active"
                    className="text-gray-600 hover:text-blue-500"
                  >
                    Blog
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to="/document"
                    className="text-gray-600 hover:text-blue-500"
                    activeClassName="active"
                  >
                    Document
                  </Link>
                </li>
                <li className="mt-2">
                  <a
                    href="https://finder.entrepreneur.gov.sl"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-600 hover:text-blue-500"
                    activeClassName="active"
                  >
                    Signup
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Completed mobile responsiveness */}

          {/* Newsletter and Social Media setion */}
          <div className="mr-8 mt-16 md:mt-5 mb-20">
            <h1 className="text-2xl font-semibold tracking-wide text-gray-600">
              Signup for Our Newsletter
            </h1>

            {/* MAILCHIMP NEWSLETTER */}
            <Newsletter />

            <p className="mt-4 text-sm font-light text-gray-600">
              Our system has a database of all mapped businesses, startups, and
              investors in Sierra Leone.
            </p>
            <h1 className="text-2xl font-semibold tracking-wide text-gray-600 mt-4">
              Get in Touch
            </h1>
            <p className="mt-2 text-gray-600">Send us message at:</p>
            <p className="mt-1 text-gray-700 font-bold">info@ecosystem.sl</p>

            <div className="mt-6">
              <ul className="flex">
                <li className="mr-3">
                  <a
                    href="https://facebook.com/ecosystem"
                    target="blank"
                    rel="noreferrer"
                  >
                    <FaFacebookF className="text-lg text-gray-500 hover:text-blue-600" />
                  </a>
                </li>
                <li className="mr-3">
                  <a
                    href="https://twitter.com/ecosystem"
                    target="blank"
                    rel="noreferrer"
                  >
                    <FaTwitter className="text-lg text-gray-500 hover:text-blue-500" />
                  </a>
                </li>
                <li className="mr-3">
                  <a
                    href="https://instagram.com/ecosystem"
                    target="blank"
                    rel="noreferrer"
                  >
                    <FaInstagram className="text-lg text-gray-500 hover:text-red-300" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://linkedin.com/ecosystem"
                    target="blank"
                    rel="noreferrer"
                  >
                    <FaLinkedin className="text-lg text-gray-500 hover:text-red-300" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Completed mobile responsiveness */}
        </div>
        {/* Completed mobile responsiveness */}
      </div>
    )
}

export default Footer
