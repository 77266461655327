import React, {useState} from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import styled, {createGlobalStyle} from "styled-components"

import Header from "./header"
import Footer from "./footer"
import ArrowUp from "./icons/ArrowUp"

const Global = createGlobalStyle`
  body, html{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
`

const MenuIcon = styled.button`
  position: fixed;
  top: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  background: transparent;
  cursor: pointer;
  border: none;
  z-index: 99;

  div {
    width: 1.5rem;
    height: 0.2rem;
    background: #fc9e32;
    border-radius: 5px;
    transform-origin: 1px;
    margin-bottom: 6px;
    transition: opacity 300ms, transform 300ms;

    :first-child {
      transform: ${({nav}) => (nav ? "rotate(45deg)" : "rotate(0)")};
    }
    :nth-child(2) {
      opacity: ${({nav}) => (nav ? "0" : "1")};
    }
    :nth-child(3) {
      transform: ${({nav}) => (nav ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
  @media (min-width: 800px) {
    display: none;
  }
`

const MenuLinks = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  background: #fff;
  /* background: #3b82f6; */

  position: fixed;
  z-index: 80;
  top: 0;
  right: 0;
  transition: ease-in-out 400ms;
  transform: ${({nav}) => (nav ? "translateX(0)" : "translateX(100%)")};

  ul {
    list-style: none;
    margin-left: -12%;
  }

  li {
    margin: 20px 20px;
    text-align: left;
  }

  a {
    text-decoration: none;
    font-size: 1.5rem;
    :hover {
      color: #91b3e9;
    }
  }
  @media (min-width: 800px) {
    display: none;
  }
`

const MainNav = styled.header`
  @media (max-width: 800px) {
    display: none;
  }
`

const Layout = ({children}) => {
    const [nav, showNav] = useState(false)

    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <div className="relative">
            <Global/>
            <MainNav>
                <Header/>
            </MainNav>

            <div
                style={{
                    margin: `0 auto`,
                }}
            >
                {/* Hamburger Menu for Mobile Navigations */}
                <MenuIcon nav={nav} onClick={() => showNav(!nav)}>
                    <div/>
                    <div/>
                    <div/>
                </MenuIcon>
                <MenuLinks nav={nav}>
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            marginTop: "1.5rem",
                            marginLeft: "1.5rem",
                        }}
                    >
                        <StaticImage
                            src="../images/logos/digital-entrepreneurship-logo.png"
                            alt="Woman"
                            width="320"
                        />
                    </div>
                    <ul>
                        <li>
                            <Link to="/" onClick={() => showNav(!nav)}>
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to="/about" onClick={() => showNav(!nav)}>
                                About
                            </Link>
                        </li>
                        <li>
                            <Link to="/team" onClick={() => showNav(!nav)}>
                                Team
                            </Link>
                        </li>
                        <li>
                            <a
                                href="https://ecosystem-finder-b3bud4jl4a-uc.a.run.app/"
                                target="blank"
                                onClick={() => showNav(!nav)}
                            >
                                Ecosystem
                            </a>
                        </li>
                        <li>
                            <Link to="/blog" onClick={() => showNav(!nav)}>
                                Blog
                            </Link>
                        </li>
                        <li className="mb-10">
                            <Link to="/document" onClick={() => showNav(!nav)}>
                                Document
                            </Link>
                        </li>
                        {/* <li>
              <Link
                to="https://ecosystem-finder-b3bud4jl4a-uc.a.run.app/auth?q=signup"
                target="_blank"
                rel="noreferrer"
                className="font-semibold text-sm tracking-wider focus:outline-none bg-blue-500 py-3 px-6 text-white rounded"
              >
                Signup
              </Link>
            </li> */}
                    </ul>
                </MenuLinks>

                {/* Pages Coponents */}
                <main>{children}</main>
                {/* Footer */}
                <Footer/>

                <div className="bottom-0 right-0 fixed flex justify-end">
                    <div className="px-4 md:px-16 bg-blue-500 py-3 rounded-t-md">
                        <ul className="flex items-center">
                            <li className="mr-2 md:mr-4 text-white">
                                <Link to="/">Startup Levels</Link>
                            </li>
                            <li className="mr-5 border-l-2 pl-5 text-white">
                                <Link to="/ecosystem-benefits">Ecosystem Value</Link>
                            </li>
                            {/* <li className="border-l-2 pl-5 text-white">
                <Link
                  to="https://ecosystem-finder-b3bud4jl4a-uc.a.run.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  How to Join?
                </Link>
              </li> */}
                            <Link to="#">
                                <li className="pl-10">
                                    <ArrowUp/>
                                </li>
                            </Link>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout
