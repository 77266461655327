import React, {useState} from "react"
import InfoSuccess from "./icons/InfoSuccess"

import addToMailchimp from "gatsby-plugin-mailchimp"

const Newsletter = () => {
    const [enteredEmail, setEnteredEmail] = useState("")
    const [enteredEmailTouched, setEnteredEmailTouched] = useState(false)
    const [message, setMessage] = useState("")

    const enteredEmailIsValid = enteredEmail.trim() !== ""
    const emailInvalidInput = !enteredEmailIsValid && enteredEmailTouched

    const emailChangedHandler = event => {
        setEnteredEmail(event.target.value)
    }

    const emailInputBlurHandler = event => {
        setEnteredEmailTouched(true)
    }

    const handleSubmit = event => {
        event.preventDefault()

        // Confirm input touched when form is submitted
        setEnteredEmailTouched(true)

        if (!enteredEmailIsValid) {
            return
        }

        // MailChimp integration method for adding emails to the mailchimp email list
        addToMailchimp(enteredEmail).then(data => {
            setMessage(
                `${data.result.toUpperCase()}, thanks for subscribing to our newsletter.`
            )
        })
        // .catch(error => {
        //   // setErrorMsg(error.msg)
        // })
        setEnteredEmail("")
        setEnteredEmailTouched(false)
    }

    return (
        <form className="py-4" onSubmit={handleSubmit}>
            <div>
                <p className="mt-2 text-gray-600">
                    Monthly news, events, and other vital news from the Sierra Leone
                    entrepreneurship space.
                </p>
            </div>

            {message && (
                <div className="my-3 rounded bg-green-100 p-2 border-l-2 border-green-500 flex justify-between">
                    <p>{message}</p> <InfoSuccess/>
                </div>
            )}

            <input
                type="email"
                name="email"
                value={enteredEmail}
                onChange={emailChangedHandler}
                onBlur={emailInputBlurHandler}
                placeholder="myemail@gmail.com"
                className={`${
                    emailInvalidInput
                        ? "py-3 px-3 border w-full rounded mt-1 text-gray-600 border-red-300"
                        : "py-3 px-3 border w-full rounded mt-1 text-gray-600"
                }`}
                autoComplete="off"
                required
            />
            {emailInvalidInput && (
                <p className="text-xs text-red-300 mt-1">
                    Email must be valid and not empty
                </p>
            )}
            <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 transition: delay-20 py-3 px-8 w-full rounded capitalize tracking-wide font-semibold mt-3"
            >
                Subscribe to Newsletter
            </button>
        </form>
    )
}

export default Newsletter
