import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => (
  <header className="bg-white shadow-xl sticky top-0 z-50">
    <div
      style={{ margin: `0 auto` }}
      className="md:container md:mx-auto md:px-10 py-2 flex items-center justify-between"
    >
      <h1 style={{ margin: 0 }} className="text-3xl font-normal text-gray-800">
        <Link
          to="/"
          style={{
            textDecoration: `none`,
          }}
          className="text-gray-800 focus:outline-none"
        >
          <StaticImage
            src="../images/logos/digital-entrepreneurship-logo.png"
            alt="Woman"
            width="400"
          />
        </Link>
      </h1>
      <ul className="flex items-center">
        <li className="md:mx-3 mx-2">
          <Link
            to="/"
            className="uppercase font-semibold lg:text-sm text-xs tracking-wider focus:outline-none"
            activeClassName="active"
          >
            Home
          </Link>
        </li>
        <li className="md:mx-3 mx-2">
          <Link
            to="/about"
            className="uppercase font-semibold lg:text-sm text-xs tracking-wider"
            activeClassName="active"
          >
            About
          </Link>
        </li>
        <li className="md:mx-3 mx-2">
          <Link
            to="/team"
            className="uppercase font-semibold lg:text-sm text-xs tracking-wider"
            activeClassName="active"
          >
            Team
          </Link>
        </li>
        <li className="md:mx-3 mx-2">
          <a
            href="https://finder.entrepreneur.gov.sl/"
            className="uppercase font-semibold lg:text-sm text-xs tracking-wider focus:outline-none"
            target="blank"
            rel="noreferrer"
            activeClassName="active"
          >
            Ecosystem
          </a>
        </li>
        <li className="md:mx-3 mx-2">
          <Link
            to="/blog"
            className="uppercase font-semibold lg:text-sm text-xs tracking-wider focus:outline-none"
            activeClassName="active"
          >
            Blog
          </Link>
        </li>
        <li className="md:mx-3 mx-2">
          <Link
            to="/document"
            className="uppercase font-semibold lg:text-sm text-xs tracking-wider focus:outline-none"
            activeClassName="active"
          >
            Document
          </Link>
        </li>
        <li className="ml-3">
          <a
            href="https://finder.entrepreneur.gov.sl/"
            target="_blank"
            rel="noreferrer"
            className="uppercase font-semibold lg:text-sm text-xs tracking-wider focus:outline-none bg-blue-500 py-3 px-6 text-white rounded"
          >
            Signup
          </a>
        </li>
      </ul>
    </div>
  </header>
)

export default Header
